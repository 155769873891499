<template>
  <div class="tour-style h-100">
    <UserLayout
      :layout-type="2"
      ref="userLayout"
      @startTour="startTour"
      @handleCourseCompletion="handleCourseCompletion"
    >
      <template v-slot:bottom-section>
        <Loader v-if="isLoading" />
        <div
          :class="[
            'h-100 vle-wrapper',
            getCurrentMenuItem,
            {
              'vle__overlay-fixed':
                getCurrentMenuItem != 'modules' && isNavOpen,
              'vle__sidenav--open':
                isVleSideNavOpen && getCurrentMenuItem !== 'modules',
              'vle-wrapper__scorm':
                isScormUnit && getCurrentMenuItem === 'modules'
            }
          ]"
          v-else
          id="qa-self-paced-id"
        >
          <div
            class="sp__content-wrapper"
            :class="{
              vle__success: isCourseCompleted,
              vle_forums: getCurrentMenuItem === 'forums'
            }"
          >
            <!-- Sidebar -->
            <Header
              @goToCourseDetail="goToCourseDetail"
              @onNavClick="onNavClick"
              @selectedCategory="handleCategoryName"
            />
            <!-- Page Content -->
            <div
              class="right__nav--wrapper"
              v-if="getCurrentMenuItem == 'forums'"
            >
              <PostList :selectedCategoryName="selectedCategoryName" />
            </div>
          </div>
        </div>
      </template>
    </UserLayout>
    <VueTour
      :steps="steps"
      :myCallbacks="myCallbacks"
      @bulletClick="myCustomNextStepCallback"
      @repeatTour="repeatTour"
      @closeTour="closeTour"
    ></VueTour>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// mixin
import courseCompletion from "@/components/SelfPaced/mixins/courseCompletion";
import goToPage from "@/components/SelfPaced/mixins/userLayout";
import vTourVle from "@/components/SelfPaced/mixins/vTourVle.js";
// components
import UserLayout from "@/components/Layout/UserLayout2";
import Header from "@/components/SelfPaced/Header.vue";
import VueTour from "@/components/WrapperComponents/VueTour.vue";
import { DISCUSSION_VIEW_SELECTOR } from "@/store/modules/discussion/state.js";
import PostList from "@/components/SelfPaced/Discussion/PostList.vue";

export default {
  mixins: [goToPage, courseCompletion, vTourVle],
  components: {
    UserLayout,
    Header,
    VueTour,
    PostList
  },
  data() {
    return {
      duration: 60,
      state: "started",
      isLoading: true,
      isNavOpen: false,
      isOpen: false,
      fixedScreenHeight: "",
      isMainViewLoading: false,
      isCourseCompleted: false,
      selectedCategoryName: {}
    };
  },
  computed: {
    ...mapGetters([
      "getLayout",
      "getCurrentMenuItem",
      "getCourseViewSelector",
      "getTimedAssessmentFlag",
      "getCurrentSequence",
      "language",
      "iframeContentStatus",
      "getDiscussionBreadcrumb",
      "getCurrentUnit",
      "vueTourStatus",
      "isVleSideNavOpen",
      "isScormUnit"
    ]),
    steps() {
      if (this.getCurrentMenuItem === "modules") {
        if (this.getCourseViewSelector === 3 || this.isScormUnit) {
          return [
            {
              target: "#selfPlacedNavbarIcon",
              content: this.$t("vle.walkthrough.model.tooltip_icons.index"),
              params: {
                placement: "right"
              }
            },
            {
              target: "#selfPlacedHeaderTabs",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.header_tabs"
              )
            },
            {
              target: "#selfPlacedExit",
              content: this.$t("vle.walkthrough.model.tooltip_icons.exit")
            },
            {
              target: "#selfPlacedPagination",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.top_pagination"
              )
            },
            {
              target: "#expandWalkthrough #walkthrowDropdown > ul",
              content: this.$t("vle.walkthrough.model.tooltip_icons.more_menu"),
              params: {
                placement: "left"
              }
            }
          ];
        } else if (this.getCourseViewSelector === 1) {
          return [
            {
              target: "#selfPlacedNavbarIcon",
              content: this.$t("vle.walkthrough.model.tooltip_icons.index"),
              params: {
                placement: "right"
              }
            },
            {
              target: "#selfPlacedHeaderTabs",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.header_tabs"
              )
            },
            {
              target: "#selfPlacedExit",
              content: this.$t("vle.walkthrough.model.tooltip_icons.exit")
            },
            {
              target: "#expandWalkthrough #walkthrowDropdown > ul",
              content: this.$t("vle.walkthrough.model.tooltip_icons.more_menu"),
              params: {
                placement: "left"
              }
            }
          ];
        } else {
          return [
            {
              target: "#selfPlacedNavbarIcon",
              content: this.$t("vle.walkthrough.model.tooltip_icons.index"),
              params: {
                placement: "right"
              }
            },
            {
              target: "#selfPlacedHeaderTabs",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.header_tabs"
              )
            },
            {
              target: "#selfPlacedExit",
              content: this.$t("vle.walkthrough.model.tooltip_icons.exit")
            },
            {
              target: "#selfPlacedPagination",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.top_pagination"
              )
            },
            {
              target: "#selfPlacedFooter",
              content: this.$t(
                "vle.walkthrough.model.tooltip_icons.footer_pagination"
              )
            },
            {
              target: "#expandWalkthrough #walkthrowDropdown > ul",
              content: `${this.$t(
                "vle.walkthrough.model.tooltip_icons.more_menu"
              )}<br/><br/>${this.$t(
                "vle.walkthrough.model.tooltip_icons.full_screen"
              )}`,
              params: {
                placement: "left"
              }
            }
          ];
        }
      } else {
        return [
          {
            target: "#selfPlacedNavbarIcon",
            content: this.$t("vle.walkthrough.model.tooltip_icons.index"),
            params: {
              placement: "right"
            }
          },
          {
            target: "#selfPlacedHeaderTabs",
            content: this.$t("vle.walkthrough.model.tooltip_icons.header_tabs")
          },
          {
            target: "#selfPlacedExit",
            content: this.$t("vle.walkthrough.model.tooltip_icons.exit")
          },
          {
            target: "#expandWalkthrough #walkthrowDropdown > ul",
            content: this.$t("vle.walkthrough.model.tooltip_icons.more_menu"),
            params: {
              placement: "left"
            }
          }
        ];
      }
    },
    containerClassName() {
      let str;
      if (this.getCourseViewSelector !== 1) {
        if (this.isScormUnit) {
          str = "sp-mainView__style";
        } else {
          str = "container sp__mainView-style";
        }
      } else {
        str = "container sp__timedView-style";
      }

      if (this.getCurrentMenuItem === "forums") {
        str = "container";
      } else {
        if (this.getTimedAssessmentFlag) {
          str = "sp__background-white container-fluid";
        } else {
          str = "sp__background-white";
        }
      }
      return str;
    },
    toastDescription() {
      return ` ${this.$t("vle.success.microcredential.subtitle")},
            ${this.$t("vle.success.microcredential.message")}
            ${this.$t("vle.success.microcredential.credentials")}`;
    }
  },
  created() {
    this.DISCUSSION_VIEW_SELECTOR = DISCUSSION_VIEW_SELECTOR;
    this.fixedScreenHeight = window.innerHeight - 62;
    if (this.getLayout !== "main") {
      this.$store.commit("SET_LAYOUT_UPDATE_TRIGGERED", true);
      this.$emit("update:layout", "main");
    } else {
      this.$store.commit("SET_LAYOUT_UPDATE_TRIGGERED", false);
      // if (
      //   this.getCurrentMenuItem !== "teams" ||
      //   this.$route.path.includes("teams")
      // ) {
      //   this.setup();
      // }
    }
    this.$store.commit("TOGGLE_VLE_SIDE_NAV", true);
    //  if (this.$route.path.includes("teams")) {
    //   this.$store.commit("SET_CURRENT_MENU_ITEM", "teams");
    //   this.onNavClick(true);
    //  }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    //   this.$store.dispatch("getDiscussionTopics", this.$route.params.id).then();
    //   let obj = {
    //     course_id: this.$route.params.id,
    //     page: 1
    //   };
    //   this.$store.dispatch("getListOfAllPosts", obj).then();
    // this.getListOfPosts()
    this.$store.commit("SET_CURRENT_MENU_ITEM", "forums");
    this.$store.commit(
      "SET_DISCUSSION_VIEW_SELECTOR",
      this.DISCUSSION_VIEW_SELECTOR.PostDetails
    );
    this.isLoading = false;
  },
  watch: {
    vueTourStatus(val) {
      if (val) {
        {
          let contentHeight = document.getElementById("ul-content__main")
              .scrollHeight,
            elem = document.getElementById("ul-tour__backdrop");

          elem.style.height = `${contentHeight + 58}px`;
        }
      }
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 400) {
        location.reload();
      }
    },

    goToCourseDetail() {
      this.goToPage({ id: this.$route.params.id }, "Courses Detail");
    },
    handleCourseCompletion() {
      this.isOpen = false;
      this.isCourseCompleted = true;
    },
    hasCredentials(section) {
      if (this._isSectionHasCredential(section)) {
        this.issueCredential(section.id);
      }
    },
    mainViewLoading(value) {
      this.isMainViewLoading = value;
    },
    onNavClick(isOpen) {
      this.isNavOpen = isOpen;
    },
    sidebarOpenClose() {
      this.isOpen = !this.isOpen;
    },
    unenrollSuccessModalClose(id) {
      this.$bvModal.hide(id);
      this.$router
        .push({
          name: "course-discovery"
        })
        .catch(() => {});
    },
    toggleLeftNavOpen() {
      this.getCurrentSequence.is_timed_assessment ||
      (this.isScormUnit && this.getCurrentMenuItem === "modules")
        ? (this.isOpen = false)
        : (this.isOpen = true);
    },
    handleCategoryName(data) {
      this.selectedCategoryName = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.vle-wrapper {
  &.vle__sidenav--open {
    .sp__content-wrapper {
      .sp__container-wrapper {
        padding: 28px 16px;
      }
    }
  }
  &.vle-wrapper__scorm {
    background: white;
    position: relative;
    .sp__content-wrapper {
      .sp__container-wrapper {
        padding: 0;
        .sp__content-data {
          margin: 0;
          padding: 0;
          position: inherit;
          height: 100%;
          .sp__background-white {
            position: inherit;
            height: 100%;
          }
        }
      }
    }
  }
  &.forums,
  &.teams {
    .sp__content-data {
      padding: 0;
      .sp__background-white {
        height: 100%;
      }
    }
    .sp__content-wrapper {
      &.vle_forums {
        max-width: calc(100% - 286px);
        margin-left: auto;
        margin-right: auto;
        padding-top: 28px;
      }
    }
    &:not(.vle__sidenav--open) {
      .right__nav--wrapper {
        max-width: 608px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .vle__overlay-fixed {
    .sp__left-nav-wrapper,
    .sp__main-view-wrapper {
      margin-left: 316px;
    }
  }
  .sp__content-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: 100%;
    .sp__container-wrapper {
      width: 100%;
      padding: 28px 80px;
    }
  }
}
.footer-container {
  margin-bottom: 20px;
}

.yourFooterClassOrId {
  margin-top: 20px;
}
.sp__left-nav-wrapper,
.sp__main-view-wrapper {
  height: 100%;
  transition: margin 0.75s ease-out;
}
.sp__left-nav-wrapper {
  position: fixed;
  z-index: 1;
}
.sp__icon-style {
  background: $brand-primary-100;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-left: -16px;
}
// borrowed from previous code
#profile-edit-modal {
  .modal-header,
  .modal-footer {
    border: none;
    padding-bottom: 8px;
  }
  .modal-body {
    padding: 8px;
  }
  .pr-edit__cta {
    padding: 16px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#completion-modal,
#microcredential-modal,
#complete-credential-modal {
  .modal-dialog {
    max-width: 650px;
  }
  .modal-header {
    border: none;
    padding: 0;
    .d-flex {
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      padding: 1rem;
    }
  }
  .modal-footer {
    justify-content: center;
    border: none;
    padding: 2%;
    .btn {
      font-size: 14px;
      line-height: 16px;
      border: none;
      padding: 10px 0;
      width: 130px;
    }
  }
  .modal-body {
    padding: 0;
    .cd-main {
      padding: 0;
    }
  }
  .cd-main__sec {
    background: transparent;
    padding: 4% 5% 0;
    box-shadow: none;
    text-align: center;
    h3 {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.2px;
      color: #0043ac;
      margin-bottom: 5px;
    }
    h5 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.2px;
      margin-bottom: 20px;
      color: #0043ac;
    }
    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #0043ac;
      display: inherit;
    }
    .cd-sec__des {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $brand-secondary-text;
      margin-bottom: 30px;
    }
    .cd-sec__footer {
      margin-top: 0;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
#complete-credential-modal {
  .cd-main__sec {
    padding: 4% 5%;
  }
}
.breadcrumb-item {
  color: $brand-neutral-200;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  &.active {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $brand-primary;
  }
}
/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */

.sp__content-data {
  width: 100%;
  padding: 8px 0px 0;
  .navbar-light {
    padding: 0;
    margin-bottom: 12px;
  }
  .navbar-collapse {
    margin-left: 1px;
    .all-posts {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }
}
.sp__breadcrumb-content {
  margin-bottom: 0px;
  font-size: 10px;
  background-color: transparent;
  padding: 8px 0;
}
.sp__breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  content: url("~@/assets/images/vle/chevron-right-breadcrumb.svg");
}
[dir="rtl"] {
  .vle__overlay-fixed {
    .sp__left-nav-wrapper,
    .sp__main-view-wrapper {
      margin-right: 316px;
      margin-left: 0;
    }
  }
  .sp__breadcrumb-content {
    .breadcrumb-item {
      padding-right: 8px;
    }
    .breadcrumb-item.active {
      padding-right: 1px;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      padding: 0 0.5em 0 0.5em;
      float: right;
    }
  }
  .right__nav--wrapper {
    padding-left: 0px;
    padding-right: 80px;
  }
}
.sp__mainView-style {
  height: auto;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0;
}
.sp__timedView-style {
  height: 100%;
  min-height: 500px;
  border-radius: 10px;
  padding: 10px;

  .sp__background-white {
    background-color: #fff !important;
  }
}

.vb__toast-content {
  display: flex;
}

.sp__success-message {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 48px;
  }
  .sp__success-content {
    margin: 12px 0px 32px;
  }
  .sp-completed__subheading {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: $brand-neutral-700;
    margin-bottom: 8px;
  }
  .sp-completed__text {
    margin-bottom: 0;
  }
  .sp__success-button {
    display: flex;
    .btn {
      &:not(:first-child) {
        margin-left: 48px;
      }
    }
  }
}
.vle__success {
  background-color: $brand-primary-50;
}

.right__nav--wrapper {
  padding-left: 80px;
  width: 100%;
  max-width: 688px;
  text-align: initial;
  .discussion__sec--content {
    text-align: left;
    margin: 0 10% 0 15%;
    .discussion__sec--head {
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: 0.1px;
      color: $brand-secondary-text;
    }
    .discussion-body {
      border: solid 1px;
      border-color: #e8e8e8 transparent #e8e8e8 transparent;
      padding-bottom: 50px;

      .body-head {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: $vle-primary-text;
        font-weight: 500;
      }
      .body-text {
        @include label-medium;
        line-height: 50px;
        color: $vle-primary-text;
      }
      .body-card {
        display: grid;
      }
    }
  }
}
</style>
